@font-face {
    font-family: 'AvertaforTBWA-Bold';
    src: url('../fonts/AvertaforTBWA-Bold.eot');
    src: url('../fonts/AvertaforTBWA-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Bold.svg#AvertaforTBWA-Bold') format('svg'),
         url('../fonts/AvertaforTBWA-Bold.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Bold.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvertaforTBWA-Extrabold';
    src: url('../fonts/AvertaforTBWA-Extrabold.eot');
    src: url('../fonts/AvertaforTBWA-Extrabold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Extrabold.svg#AvertaforTBWA-Extrabold') format('svg'),
         url('../fonts/AvertaforTBWA-Extrabold.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Extrabold.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Extrabold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'AvertaforTBWA-Extrathin';
    src: url('../fonts/AvertaforTBWA-Extrathin.eot');
    src: url('../fonts/AvertaforTBWA-Extrathin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Extrathin.svg#AvertaforTBWA-Extrathin') format('svg'),
         url('../fonts/AvertaforTBWA-Extrathin.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Extrathin.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Extrathin.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'AvertaforTBWA-Light';
    src: url('../fonts/AvertaforTBWA-Light.eot');
    src: url('../fonts/AvertaforTBWA-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Light.svg#AvertaforTBWA-Light') format('svg'),
         url('../fonts/AvertaforTBWA-Light.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Light.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'AvertaforTBWA-Regular';
    src: url('../fonts/AvertaforTBWA-Regular.eot');
    src: url('../fonts/AvertaforTBWA-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Regular.svg#AvertaforTBWA-Regular') format('svg'),
         url('../fonts/AvertaforTBWA-Regular.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Regular.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'AvertaforTBWA-Thin';
    src: url('../fonts/AvertaforTBWA-Thin.eot');
    src: url('../fonts/AvertaforTBWA-Thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Thin.svg#AvertaforTBWA-Thin') format('svg'),
         url('../fonts/AvertaforTBWA-Thin.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Thin.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Thin.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'AvertaforTBWA-Semibold';
    src: url('../fonts/AvertaforTBWA-Semibold.eot');
    src: url('../fonts/AvertaforTBWA-Semibold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Semibold.svg#AvertaforTBWA-Semibold') format('svg'),
         url('../fonts/AvertaforTBWA-Semibold.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Semibold.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Semibold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'AvertaforTBWA-Black';
    src: url('../fonts/AvertaforTBWA-Black.eot');
    src: url('../fonts/AvertaforTBWA-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/AvertaforTBWA-Black.svg#AvertaforTBWA-Black') format('svg'),
         url('../fonts/AvertaforTBWA-Black.ttf') format('truetype'),
         url('../fonts/AvertaforTBWA-Black.woff') format('woff'),
         url('../fonts/AvertaforTBWA-Black.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}