/* General */

body, html {
    font-family: 'AvertaforTBWA-Regular', 'Arial';
    font-size: 12px;
    color: #000;
}

.custom-section {
    text-align: center;
    font-size: 12px;
}

h1 {
    font-size: 60px;
    font-weight: normal;
}

p {
    font-size: 12px;
    font-weight: normal;
}

a {
    text-decoration: none;
    color: black;
}

a:visited {
    color: black;
}

.height-auto {
    height: auto !important;
    position: relative;
}

.height-auto .fp-tableCell {
    height: auto !important;
}

@media only screen and (max-width: 768px) {
    /* body, html {
        overflow: hidden;
    } */
}

/* Personajes */

.st0{fill:#FFFFFF;}
.st1{fill:#7F7F7F;}
.st2{fill:#231F20;}
.st3{fill:#FFCD00;}

/* Animations */

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes moveFirstSlideShow {
    100% { 
        transform: translateX(-50%);  
    }
}

@keyframes moveSecondSlideShow {
    100% { 
        transform: translateX(-50%);  
    }
}

/* Fix FullPage */

#fp-nav.fp-left {
    left: 50px;
    display: none !important;
}

#fp-nav ul li {
    margin: 24px 7px;
}

/* Fix Carousel */

.react-multi-carousel-list {
    overflow: visible;
}

/* Next Section Button */

.next-section {
    position: fixed;
    z-index: 10;
    bottom: 70px;
    right: 60px;
    transition: transform .5s;
}

.next-section-black path {
    fill: black;
    background-color: black;
}

.next-section-white path {
    fill: white;
    background-color: white;
}

@media only screen and (max-width: 992px) {
    .next-section {
        display: none;
    }
}

/* Modal Intro */

.modal-intro {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff url(../images/bg-video.jpg) no-repeat;
    background-size: cover;
    z-index: 99999999;
    top: 0;
    left: 0;
    display: table;
    text-align: center;
}

.modal-intro .modal-intro-img{
    display: table-cell;
    vertical-align: middle;
}

@media only screen and (max-width: 768px) {
    .modal-intro .modal-intro-img img{
        width: 200px;
    }
}

@media only screen and (max-height: 820px) {
    .modal-intro .modal-intro-img img{
        width: 300px;
    }
}

/* Menu */

.menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
}

.menu .logo {
    position: absolute;
    left: 60px;
    top: 40px;
}

.menu .logo img {
    width: 106px;
}

.menu .actions {
    list-style-type: none;
    display: block;
    margin: 0;
    position: absolute;
    right: 60px;
    top: 40px;
}

.menu .actions li {
    display: inline-block;
}

.menu .actions li a {
    padding: 24px 0px;
    font-size: 18px;
    cursor: pointer;
    display: block;
    margin-left: 38px;
    text-transform: uppercase;
}

.menu .actions li a:hover {
    text-decoration: none;
}

.menu .actions li a::before {
    content: "{";
    margin-right: 20px;
    font-size: 24px;
    visibility: hidden;
}

.menu .actions li a::after {
    content: "}";
    margin-left: 20px;
    font-size: 24px;
    visibility: hidden;
}

.menu .actions .active a::before {
    content: "{";
    margin-right: 20px;
    font-size: 24px;
    visibility: visible;
}

.menu .actions .active a::after {
    content: "}";
    margin-left: 20px;
    font-size: 24px;
    visibility: visible;
}

.menu .actions button:hover, .actions a:hover {

}

@media only screen and (max-width: 1200px) {
    .menu {
        display: none;    
    }
}

/* Menu Mobile */

.menu-mobile {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    width: 100%;
    z-index: 999;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;
}

.menu-mobile .logo {
    position: absolute;
    left: 60px;
    top: 40px;
}

.menu-mobile .logo img {
    width: 106px;
}

.menu-mobile .hamburger {
    position: fixed;
    right: 60px;
    top: 55px;
}

.menu-mobile .hamburger img {
    width: 60px;
}

.menu-mobile .close-button {
    position: absolute;
    right: 60px;
    top: 55px;
}

.menu-mobile .close-button img {
    width: 45px;
}

.menu-mobile .actions-container-closed {
    background: #fff;
    width: 100%;
    height: 100vh;
    display: table;
    position: fixed;
    top: 0;
    left: 100%;
    text-align: center; 
    background-image: url(../images/bg-menu.jpg);
    background-size: cover;
    background-repeat: no-repeat; 
}

.menu-mobile .actions-container-closed-animated {
    background: #fff;
    width: 100%;
    height: 100vh;
    display: table;
    position: fixed;
    top: 0;
    left: 100%;
    text-align: center; 
    background-image: url(../images/bg-menu.jpg);
    background-size: cover;
    background-repeat: no-repeat; 
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
}

.menu-mobile .actions-container-opened {
    background: #fff;
    width: 100%;
    height: 100vh;
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    background-image: url(../images/bg-menu.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.menu-mobile .actions-container-inner {
    display: table-cell;
    vertical-align: middle;
}

.menu-mobile .actions {
    display: inline-block;
    list-style-type: none;
    position: relative;
    max-width: 400px;
    text-align: left;
    margin: 0 auto;
    padding: 0px 40px;
}

.menu-mobile .actions li {
    display: block;
    width: 100%;
}

.menu-mobile .actions li a {
    padding: 0px 0px;
    font-size: 18px;
    cursor: pointer;
    display: block;
    text-transform: uppercase;
    font-size: 32px;
    color: #FDCC00;
    font-family: 'AvertaforTBWA-Black';
}

.menu-mobile .actions li a:hover {
    text-decoration: none;
}

.menu-mobile .actions li a .title{
    background: -webkit-linear-gradient(to left, #FDCC00, #F5A60C);
    background: linear-gradient(to left, #FDCC00, #F5A60C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.menu-mobile .actions li a .num{
    display: inline-block;
    font-size: 62px;
    -webkit-text-stroke: 2px #FDCC00;
    font-family: 'AvertaforTBWA-Black';
    color: rgba(255,255,255,0);
    margin-right: -10px;
}

@media only screen and (max-width: 1366px) {
    .menu .actions li a{
        margin-left: 6px;
    }
}

@media only screen and (max-width: 1200px) {
    .menu-mobile {
        display: block;    
    }
}

@media only screen and (max-width: 992px) {
    .menu-mobile {
        /* position: fixed;     */
        /* background: rgba(255,205,0,.97); */
        height: 140px;
    }
}

@media only screen and (max-width: 576px) {
    .menu-mobile {
        /* position: fixed;     */
        /* background: rgba(255,205,0,.97); */
        height: 100px;
    }
    
    .menu-mobile .logo {
        left: 40px;
        top: 34px;
    }

    .menu-mobile .logo img {
        width: 60px;
    }

    .menu-mobile .hamburger {
        right: 40px;
        top: 40px;
    }

    .menu-mobile .hamburger img {
        width: 36px;
    }

    .menu-mobile .close-button {
        right: 40px;
        top: 50px;
    }

    .menu-mobile .close-button img {
        width: 40px;
    }
}

/* Section 01 */

#bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section01 {

}

.section01 h1 { 
    position: relative;
    color: black;
    max-width: 1000px;
    margin: auto;
    text-align: left;
    line-height: 1.5em;
    margin-bottom: 0.4em;
    font-family: 'AvertaforTBWA-Bold';
}

.section01 h1 span{ 
    text-transform: uppercase;
    font-family: 'AvertaforTBWA-Black';
}

.section01 p { 
    position: relative;
    max-width: 1000px;
    margin: auto;
    font-size: 20px;
    text-align: left;
    font-weight: normal;
    font-family: 'AvertaforTBWA-Light';
}

.section01 p b { 
    font-family: 'AvertaforTBWA-Regular';
}

.section01 .videoButton {
    position: relative;
    max-width: 1000px;
    margin: auto;
    font-size: 20px;
    text-align: left;
    display: block;
    margin-top: 20px;
    margin-bottom: 40px;
}

.section01 .videoButton img {
    width: 70px;
    height: 70px;
}

@media only screen and (max-width: 1255px) {
    .section01 h1 {
        padding: 0 60px;
    }

    .section01 p { 
        padding: 0 60px;
    }

    .section01 .videoButton {
        padding: 0 60px;
    }
}

@media only screen and (max-width: 1024px) {
    .section01 h1 {
        font-size: 50px;
    }

    .section01 p { 
        font-size: 18px;
    }
}

@media only screen and (max-width: 992px) {
    /* .section01 {
        padding-top: 100px;
    } */
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 576px) {
    .section01 h1 {
        padding: 80px 40px 0 40px;
        font-size: 40px;
        text-align: center;
    }

    .section01 p { 
        font-size: 16px;
        padding: 0 40px 0px 40px;
    }

    .section01 .videoButton {
        text-align: center;
    }
}

@media only screen and (max-width: 375px) {
    .section01 h1 {
        padding: 140px 40px 0 40px;
        font-size: 36px;
    }

    .section01 p { 
        font-size: 16px;
        padding: 0 40px;
        padding-bottom: 0px;
    }
}

/* Section 02 */

.section02 .col-uno {
    width: 100%;
    height: 100%;
    text-align: center;
    display: table;
    vertical-align: middle;
}

.section02 .col-uno div {
    display: table-cell;
    vertical-align: middle;
}

.section02 .col-uno h1 {
    text-transform: uppercase;
}

.section02 .col-uno h1 span {
    display: block;
    font-size: 100px;
    -webkit-text-stroke: 2px #FDCC00;
    color: white;
    font-family: 'AvertaforTBWA-Black';
}

.section02 .col-uno h1 img {
    margin-top: -120px;
    margin-left: 86px;
}

.section02 .col-dos {
    width: 100%;
    height: 100%;
    background-image: url(../images/bg-manifiesto.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
}

.section02 .col-dos div {
    position: relative;
    left: -200px;
    top: 65%;
    transform: translate(0, -50%);
}

.section02 .col-dos div img {
    width: 400px;
    height: auto;
    position: relative;
    animation: float 6s ease-in-out infinite;
}

.section02 h1 { 
    position: relative;
    color: black;
    max-width: 1000px;
    margin: auto;
    text-align: left;
    line-height: 1.5em;
    margin-bottom: 0.4em;
    font-family: 'AvertaforTBWA-Bold';
}

.section02 p { 
    position: relative;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 18px;
    font-size: 20px;
    text-align: left;
    font-weight: normal;
    font-family: 'AvertaforTBWA-Light';
}

.section02 p:last-child {
    margin-bottom: 0;
}

.section02 p b { 
    font-family: 'AvertaforTBWA-Regular';
}

@media only screen and (max-width: 1635px) {
    .section02 h1 {
        padding: 0 120px;
    }

    .section02 p { 
        padding: 0 120px;
    }
}

@media only screen and (max-width: 1255px) {
    .section02 h1 {
        padding: 0 170px 0 60px;
    }

    .section02 p { 
        padding: 0 170px 0 60px;
    }
}

@media only screen and (max-width: 1024px) {
    .section02 .col-dos div img {
        width: 300px;
    }

    .section02 .col-dos div {
        left: -150px;
    }
}

@media only screen and (max-width: 992px) {
    .section02 .col-uno {
        padding: 80px 0 80px 0;
    }

    .section02 .col-dos {
        background: none;
        height: auto;
        text-align: center;
    }
    
    .section02 h1 {
        padding: 0 60px;
    }

    .section02 p { 
        padding: 0 60px;
    }

    .section02 .col-dos div {
        position: inherit;
        margin: 0 auto;
        transform: translate(0, 0);
        display: inline-block;
    }
    
    .section02 .col-dos div img {
        width: 200px;
        height: auto;
        position: relative;
        animation: float 6s ease-in-out infinite;
    }
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-height: 820px) {
    .section02 .col-uno h1 span{
        font-size: 80px;
    }
    .section02 .col-uno h1 img{
        width: 270px;
        margin-top: -140px;
        margin-left: 70px;
    }
    .section02 .col-dos div img {
        width: 300px;
    }

    .section02 .col-dos div {
        left: -150px;
    }
}

@media only screen and (max-width: 576px) {
    .section02 .col-uno h1 {
        padding: 0 40px 0 40px;
        margin-bottom: 0;
    }

    .section02 .col-uno h1 span {
        font-size: 52px;
        line-height: 1.2em;
        -webkit-text-stroke: 1px #FDCC00;
    }

    .section02 .col-uno h1 img {
        margin-top: -120px;
        margin-left: 26px;
        width: 70%;
    }

    .section02 p { 
        font-size: 16px;
        padding: 0 40px;
    }

    .section02 .col-dos div img {
        width: 160px;
    }
}

/* Section 03 */

.section03 .col-uno {
    width: 100%;
    height: 100%;
    text-align: center;
    display: table;
    vertical-align: middle;
}

.section03 .col-uno div {
    display: table-cell;
    vertical-align: middle;
}

.section03 .col-uno h1 {
    text-transform: uppercase;
}

.section03 .col-uno h1 span {
    display: block;
    font-size: 100px;
    -webkit-text-stroke: 2px #FDCC00;
    color: white;
    font-family: 'AvertaforTBWA-Black';
}

.section03 .col-uno h1 img {
    margin-top: -120px;
    margin-left: 86px;
}

.section03 .col-dos {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
}

/* .section03 .col-dos div {
    position: relative;
    left: -250px;
    top: 65%;
    transform: translate(0, -50%);
    pointer-events: none;
}

.section03 .col-dos div img {
    width: 500px;
    height: auto;
    position: relative;
    animation: float 6s ease-in-out infinite;
    pointer-events: none;
} */

.section03 h1 { 
    position: relative;
    color: black;
    max-width: 1000px;
    margin: auto;
    text-align: left;
    line-height: 1.5em;
    margin-bottom: 0.4em;
    font-family: 'AvertaforTBWA-Bold';
}

.section03 p { 
    position: relative;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 18px;
    font-size: 20px;
    text-align: left;
    font-weight: normal;
    font-family: 'AvertaforTBWA-Light';
}

.section03 p:last-child {
    margin-bottom: 0;
}

.section03 p b { 
    font-family: 'AvertaforTBWA-Regular';
}

.tech-slideshow {
    height: 65px;
    width: 100%;
    position: relative;
    display: block !important;
    margin-bottom: 4px;
    overflow: hidden;
}

.tech-slideshow .mover-1 {
    /* animation: moveFirstSlideShow 80s linear infinite alternate; */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4000px;
    display: block !important;
    text-align: left;
    transform: translateX(0%); 
}

.tech-slideshow .mover-1 a {
    font-size: 42px;
    display: block;
    float: left;
    margin-right: 30px;
    font-family: 'AvertaforTBWA-Black';
    -webkit-text-stroke: 1px black;
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
}

.tech-slideshow .mover-1 a:hover, .tech-slideshow .mover-1 .active {
    -webkit-text-stroke: 0px black;
    color: black;
}

.tech-slideshow .mover-2 {
    /* animation: moveFirstSlideShow 80s linear infinite alternate-reverse; */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4000px;
    display: block !important;
    text-align: left;
    transform: translateX(-50%); 
}

.tech-slideshow .mover-2 a {
    font-size: 42px;
    display: block;
    float: left;
    margin-right: 30px;
    font-family: 'AvertaforTBWA-Black';
    -webkit-text-stroke: 1px black;
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
}

.tech-slideshow .mover-2 a:hover, .tech-slideshow .mover-2 .active {
    -webkit-text-stroke: 0px black;
    color: black;
}

.tech-slideshow .mover-3 {
    /* animation: moveFirstSlideShow 80s linear infinite alternate; */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4000px;
    display: block !important;
    text-align: left;
    transform: translateX(0%); 
}

.tech-slideshow .mover-3 a {
    font-size: 42px;
    display: block;
    float: left;
    margin-right: 30px;
    font-family: 'AvertaforTBWA-Black';
    -webkit-text-stroke: 1px black;
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
}

.tech-slideshow .mover-3 a:hover, .tech-slideshow .mover-3 .active {
    -webkit-text-stroke: 0px rgb(0, 0, 0);
    color: black;
}

@media only screen and (max-width: 1635px) {
    .section03 h1 { 
        padding: 0 60px;
    }
}

@media only screen and (max-width: 1255px) {
    .section03 h1 { 
        padding: 0 60px;
    }
}

@media only screen and (max-width: 992px) {
    .section03 .col-uno {
        padding: 80px 0 80px 0;
    }

    .section03 .col-dos {
        height: auto;
        text-align: center;
        background: none;
    }

    .section03 h1 { 
        padding: 0 60px;
    }

    .section03 .col-dos img{
        width: auto;
        height: 400px;
    }

    .tech-slideshow .mover-1 a {
        font-size: 36px;
    }

    .tech-slideshow .mover-2 a {
        font-size: 36px;
    }

    .tech-slideshow .mover-3 a {
        font-size: 36px;
    }
}

@media only screen and (max-height: 820px) {
    .section03 .col-uno h1 span{
        font-size: 80px;
    }
    .section03 .col-uno h1 img{
        width: 270px;
        margin-top: -140px;
        margin-left: 70px;
    }
}

@media only screen and (max-width: 576px) {
    .section03 .col-uno h1 {
        padding: 0 40px 0 40px;
        margin-bottom: 0;
    }

    .section03 .col-uno h1 span {
        font-size: 52px;
        line-height: 1.2em;
        -webkit-text-stroke: 1px #FDCC00;;
    }

    .section03 .col-uno h1 img {
        margin-top: -120px;
        margin-left: 26px;
        width: 70%;
    }

    .section03 .col-dos img {
        height: 240px;
    }
}

/* Section 04 */

#bg-video-section-04 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section04 .row-uno {
    background: #fff;
    position: relative;
    height: 75%;
    text-align: center;
}

.section04 .row-dos {
    position: relative;
    height: 25%;
    width: 100%;
}

.section04 .row-dos .container-slider {
    height: 520px;
    margin-top: -370px;
    width: 100%;
    text-align: center;
    /* overflow: scroll;
    -webkit-overflow-scrolling: touch; */
}

.section04 .row-dos .slider {
    height: 100%;
    width: auto;
    margin: auto;
    display: inline-block;
}

.section04 .row-dos svg {
    display: block;
    height: 100%;
    width: auto;
}

.section04 .row-uno {
    display: table;
    width: 100%;
}

.section04 .row-uno div {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.section04 .row-uno div h1 {
    text-transform: uppercase;
    max-width: 1100px;
    margin: auto;
    margin-top: -80px;
}

.section04 .row-uno div h1 span:first-child {
    display: block;
    font-size: 100px;
    -webkit-text-stroke: 2px #FDCC00;
    color: white;
    font-family: 'AvertaforTBWA-Black';
}

.section04 .row-uno div h1 img {
    margin-top: -150px;
    margin-left: 86px;
}

.section04 .tooltip-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 180px;
    height: auto;
    background: #f7d14c;
    color: black;
    z-index: 999;
    text-align: left;
    padding: 10px 10px;
    pointer-events: none;
}

.section04 .tooltip-container p {
    margin: 0px;
    padding: 0px;
}

.section04 .tooltip-container p:first-child {
    font-family: 'AvertaforTBWA-Bold';
    font-size: 14px;
}

.section04 .tooltip-container .triangulo {
    position: absolute;
    bottom: -13px;
    left: 83px;
}

@media only screen and (max-width: 1024px) {
    .section04 .row-uno div h1 {
        padding-left: 60px;
    }
}

@media only screen and (max-width: 992px) {
    .section04 .row-uno {
        position: relative;
        height: auto;
        display: block;
        padding: 160px 60px 80px 60px;
    }
    
    .section04 .row-dos {
        position: relative;
        height: auto;
        display: block;
        width: 100vw;
    }

    .section04 .row-uno div h1 {
        margin-bottom: 240px;
    }

    .section04 .row-dos .container-slider {
        width: 100vw;
        overflow-x: hidden;
    }
}

@media only screen and (max-height: 820px) {
    .section04 .row-dos .container-slider{
        height: 420px;
        margin-top: -283px;
    }
    .section04 .row-uno div h1 span:first-child{
        font-size: 80px;
    }
    .section04 .row-uno div h1 img{
        width: 190px;
        margin-top: -140px;
        margin-left: 70px;
    }
}

@media only screen and (max-width: 576px) {
    .section04 .row-uno {
        padding: 160px 40px 80px 40px;
        height: 500px;
    }

    .section04 .row-uno div h1{
        padding: 0;
        margin-bottom: 0;
    }

    .section04 .row-uno div h1 span:first-child {
        font-size: 58px;
        line-height: 1.2em;
        -webkit-text-stroke: 1px #FDCC00;
    }

    .section04 .row-uno div h1 img {
        margin-top: -120px;
        margin-left: 26px;
        width: 160px;
    }

    .section04 .row-uno div {
        display: block;
    }

    .section04 .row-dos .container-slider {
        height: 480px;
    }
}

/* Section 05 */

.section05 .col-uno {
    width: 100%;
    height: auto;
    text-align: center;
    display: table;
    vertical-align: middle;
    background-color: white;
}

.section05 .col-uno div {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.section05 .col-uno h1 {
    text-transform: uppercase;
    max-width: 100%;
    padding: 0 0 0 200px;
}

.section05 .col-uno h1 span {
    display: block;
    font-size: 100px;
    -webkit-text-stroke: 2px #FDCC00;
    color: white;
    font-family: 'AvertaforTBWA-Black';
}

.section05 .col-uno h1 img {
    margin-top: -120px;
    margin-left: 86px;
}

.section05 .col-dos {
    max-width: 100%;
    margin: auto;
    padding: 0 0 0 200px;
}

.section05 .col-dos .carousel-item-project {
    padding: 0 20px 0 20px;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container {
    position: relative;
    width: 100%;
    height: 350px;
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 2px 0px rgba(249,230,153,1);
        -moz-box-shadow: 5px 5px 2px 0px rgba(249,230,153,1);
            box-shadow: 5px 5px 2px 0px rgba(249,230,153,1);
    display: table;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container .carousel-item-project-inner-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    /* display: none; */
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container .carousel-item-project-inner-container .carousel-item-project-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: auto;
    height: 70px;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container .carousel-item-project-inner-container .carousel-item-project-description {
    display: inline-block;
    text-align: left;
    width: 50%;
    height: 1px;
    background: #f00;
    padding: 0 20px;
    display: none;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container .carousel-item-project-inner-container .carousel-item-project-image {
    width: 60%;
    display: block;
    display: inline-block;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container .carousel-item-project-inner-container .nissan {
    width: auto;
    height: 60px;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container .carousel-item-project-inner-container .bbva {
    width: 120px;
    height: auto;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-container .carousel-item-project-inner-container .nr {
    width: 90px;
    height: auto;
}

.section05 .col-dos .carousel-item-project .carousel-item-project-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .section05 .col-uno h1 {
        padding: 0 0 0 60px;
    }

    .section05 .col-dos {
        width: 100vw;
        padding: 0 0 0 60px;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 1024px) {
    .section05 .col-uno h1 {
        padding: 0 0 0 60px;
    }

    .section05 .col-dos {
        width: 100vw;
        padding: 0 0 0 60px;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 992px) {
    .section05 .col-uno {
        display: block;
    }
    
    .section05 .col-uno div {
        display: block;
    }

    .section05 .col-uno h1 {
        display: inline-block;
        padding: 80px 0 80px 60px;    
    }

    .section05 .col-dos {
        width: 100vw;
        padding: 0 0 0 60px;
        overflow-x: hidden;
    }

    .section05 {
        padding-bottom: 120px;
    }
}

@media only screen and (max-height: 820px) {
    .section05 .col-uno h1 {
        padding: 0 0 0 60px;
    }

    .section05 .col-dos {
        width: 100vw;
        padding: 0 0 0 60px;
        overflow-x: hidden;
    }
    .section05 .col-uno h1 span{
        font-size: 80px;
    }
    .section05 .col-uno h1 img{
        width: 270px;
        margin-top: -140px;
        margin-left: 70px;
    }
    .section05 .col-dos .carousel-item-project .carousel-item-project-container{
        height: 250px;
    }
}

@media only screen and (max-width: 576px) {
    .section05 .col-uno {
        padding: 80px 0 40px 0;
    }
    
    .section05 .col-uno h1{
        padding: 0 40px;
        margin-bottom: 0;
    }

    .section05 .col-uno h1 span {
        font-size: 52px;
        line-height: 1.2em;
        -webkit-text-stroke: 1px #FDCC00;
    }

    .section05 .col-uno h1 img {
        margin-top: -120px;
        margin-left: 26px;
        width: 70%;
    }

    .section05 .col-dos {
        padding: 0 0 0 40px;
    }

    .section05 .col-dos .carousel-item-project .carousel-item-project-container {
        height: 320px;
    }
}

/* Modal */

#modal-box-projects {
    position: fixed;
    z-index: 9999999999999999999;
    /* background: #f5f5f5; */
    /* display: table; */
    text-align: center;
    display: none;
}

#modal-box-projects .modal-item-project {
    padding: 0 0 0 0;
    width: 100%;
    height: 100%;
}

#modal-box-projects .modal-item-project .modal-item-project-container {
    position: absolute;
    width: 100%;
    height: 350px;
    display: block;
    background: #fff;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-logo {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 40px;
    width: auto;
    height: 70px;
    display: block;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-video {
    padding-left: 10px;
    padding-right: 10px;
    width: 70%;
    display: inline-block;
    opacity: 0;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-video video {
    width: 100%;
    height: auto;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-video .portrait {
    width: auto;
    height: 100%;
    max-height: 520px;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-text {
    text-align: left;
    font-size: 16px;
    opacity: 0;
    width: 30%;
    display: inline-block;
    padding: 0 10px;
    vertical-align: top;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-text .modal-text-title{
    font-size: 32px;
    font-family: 'AvertaforTBWA-Bold';
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-text p{
    font-size: 16px;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-text p:last-child{
    padding-bottom: 0px;
    margin-bottom: 0px;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .nissan {
    width: auto;
    height: 60px;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .bbva {
    width: 120px;
    height: auto;
}

#modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .nr {
    width: 90px;
    height: auto;
}

@media only screen and (max-width: 768px) {
    #modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-video {
        width: 100%;
    }

    #modal-box-projects .modal-item-project .modal-item-project-container .modal-item-project-inner-container .modal-item-project-text {
        width: 100%;
    }
}

/* Modal Video Mil24 */

#modal-video-mil24 {
    position: fixed;
    z-index: 9999999999999999999;
    text-align: center;
    display: none;
}

/* Section 06 */

#bg-video-section-06 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section06 .col-uno {
    width: 100%;
    height: 100%;
    text-align: center;
    display: table;
    vertical-align: middle;
    background-color: white;
}

.section06 .col-uno div {
    display: table-cell;
    vertical-align: middle;
}

.section06 .col-uno h1 {
    text-transform: uppercase;
}

.section06 .col-uno h1 span {
    display: block;
    font-size: 100px;
    -webkit-text-stroke: 2px #FDCC00;
    color: white;
    font-family: 'AvertaforTBWA-Black';
}

.section06 .col-uno h1 img {
    margin-top: -120px;
    margin-left: 86px;
}

.section06 .col-dos {
    width: 100%;
    height: 100%;
    text-align: left;
}

.section06 .col-dos div {
    position: absolute;
    right: 0px;
    top: 65%;
    transform: translate(0, -50%);
}

.section06 .col-dos div img {
    width: 680px;
    height: auto;
    position: relative;
}

.section06 h1 { 
    position: relative;
    color: black;
    max-width: 800px;
    margin: auto;
    text-align: left;
    font-family: 'AvertaforTBWA-Bold';
}

.section06 p { 
    position: relative;
    max-width: 800px;
    margin: auto;
    margin-bottom: 0px;
    font-size: 20px;
    text-align: left;
    font-weight: normal;
    font-family: 'AvertaforTBWA-Light';
    text-transform: uppercase;
    padding-left: 90px;
}

.section06 p:last-child {
    margin-bottom: 0;
}

.section06 p b { 
    font-family: 'AvertaforTBWA-Regular';
}

.section06 h2 {
    max-width: 800px;
    margin: auto;
    text-transform: uppercase;
    color: black;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 90px;
}

.section06 h2 a {
    text-decoration: none;
    color: black;
    font-family: 'AvertaforTBWA-Bold';
    font-size: 32px;
}

.section06 h2 a:hover {
    text-decoration: underline;
}

.section06 h2 a:visited {
    color: black;
}

.section06 h3 {
    max-width: 800px;
    margin: auto;
    text-transform: uppercase;
    color: black;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 90px;
    font-family: 'AvertaforTBWA-Bold';
}

.section06 h3 a {
    text-decoration: none;
    color: black;
    font-family: 'AvertaforTBWA-Bold';
    /* font-size: 26px; */
}

.section06 h3 a:hover {
    text-decoration: underline;
}

.section06 h3 a:visited {
    color: black;
}

.section06 .col-uno .creditos {
    margin: auto;
    max-width: 800px;
    width: 100%;
    display: block;
    padding-left: 90px;
    text-align: left;
    margin-top: 80px;
}

.section06 .col-uno .creditos p {
    display: inline-block;
    text-align: left;
    padding-left: 0;
    width: 50%;
    font-family: 'AvertaforTBWA-Light';
    text-transform: none;
}

.section06 .col-uno .creditos p a {
    
}

.section06 .col-uno .creditos p a:hover {
    color: #000;
}

@media only screen and (max-width: 1024px) {
    .section06 h1 {
        padding: 0 60px;
    }

    .section06 .col-uno h1 span {
        font-size: 60px;
    }

    .section06 .col-uno h1 img {
        width: 60%;
    }

    .section06 h2 {
        padding-left: 60px;
    }

    .section06 h3 {
        padding-left: 60px;
    }

    .section06 .col-uno .creditos {
        padding-left: 60px;
    }

    .section06 .col-dos div img {
        width: 480px;
    }
}

@media only screen and (max-width: 992px) {
    .section06 .col-uno {
        padding: 80px 0 120px 0;
        display: block;
    }

    .section06 .col-uno div {
        display: block;    
    }

    .section06 h1 {
        padding: 0 60px;
    }

    .section06 p { 
        padding: 0 60px;
    }

    .section06 .col-dos {
        height: auto;
        text-align: center;
    }

    .section06 .col-uno h1 span {
        font-size: 70px;
    }

    .section06 .col-uno h1 img {
        width: 80%;
    }

    .section06 h2 {
        padding-left: 60px;
    }

    .section06 h3 {
        padding-left: 60px;
    }

    .section06 .col-uno .creditos {
        padding-left: 60px;
    }
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-height: 820px) {
    .section06 .col-uno h1{
        padding-left: 60px;
    }
    .section06 .col-uno h1 span{
        font-size: 60px;
    }
    .section06 .col-uno h1 img{
        width: 400px;
        margin-top: -113px;
        margin-left: 48px;
    }
    
    .section06 h2 {
        padding: 0 60px;
    }

    .section06 h2 a {
        font-size: 30px;    
    }

    .section06 h3 {
        padding: 0 60px;
    }

    .section06 h3 a {
        /* font-size: 30px;     */
    }

    .section06 p {
        padding: 0 60px;
        font-size: 18px;
    }

    .section06 .col-uno .creditos {
        padding: 0 60px;
        margin-top: 40px;
    }

    .section06 .col-uno .creditos p {
        font-size: 18px;
    }

    .section06 .col-dos div img {
        width: 480px;
    }
}

@media only screen and (max-width: 576px) {
    .section06 .col-uno {
        padding: 80px 0 40px 0;
    }
    
    .section06 .col-uno h1{
        padding: 0 40px;
        margin-bottom: 0;
    }

    .section06 .col-uno h1 span {
        font-size: 52px;
        line-height: 1em;
        -webkit-text-stroke: 1px #FDCC00;
    }

    .section06 .col-uno h1 img {
        margin-top: -120px;
        margin-left: 26px;
        width: 90%;
    }

    .section06 h2 {
        padding: 0 40px;
    }

    .section06 h2 a {
        font-size: 30px;    
    }

    .section06 h3 {
        padding: 0 40px;
    }

    .section06 h3 a {
        font-size: 13px;    
    }

    .section06 p {
        padding: 0 40px;
        font-size: 18px;
    }

    .section06 .col-uno .creditos {
        padding: 0 40px;
    }

    .section06 .col-uno .creditos p {
        font-size: 18px;
    }
}

/* Modal Creditos */

.modal-creditos {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255,255,255,0.6);
    display: none;
    text-align: center;
}

.modal-creditos .modal-creditos-inner{
    display: table-cell;
    vertical-align: middle;
}

.modal-creditos .modal-creditos-inner .modal-creditos-box{
    display: inline-block;
    background: #fff;
    padding: 40px 80px;
    position: relative;
}

.modal-creditos .modal-creditos-inner .modal-creditos-box p{
    font-size: 16px;
    text-align: left;
}

.modal-creditos .modal-creditos-inner .modal-creditos-box ul{
    list-style: none;
    text-align: left;
    padding: 0 0 0 10px;
}

.modal-creditos .modal-creditos-inner .modal-creditos-box ul li{
    text-align: left;
    font-size: 14px;
}

@media only screen and (max-width: 576px) { 
    .modal-creditos .modal-creditos-inner .modal-creditos-box{
        padding: 40px 60px;
    }
}

@media only screen and (max-width: 375px) {
    .modal-creditos .modal-creditos-inner .modal-creditos-box{
        padding: 40px 40px;
    }
}

/* Responsive */

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 768px) { 

}

@media only screen and (max-width: 576px) { 

}